import React from 'react'

import styled from 'styled-components'

import { devices, margins, paddings, Container } from './'


export const Block = styled.div`
    ${margins}
    ${paddings}
    width: 100%;
    &:before, &:after {
        content: '';
        display: table;
        clear: both;
    }
`

export const ImageCard = styled.div`
    width: 100%;
    height: ${({ height = 200 }) => height}px;
    background: center / ${({ orientation }) => orientation || 'cover'} no-repeat url(${({ background }) => background});
`

const SlideImageCardComp = styled(ImageCard)`
    height: 440px;
    margin-bottom: 0;
    background-position: top;

    @media ${devices.small} {
        height: 300px;
    }
`

const SlideCaption = styled.div`
    font-size: 8px;
    padding-top: 10px;
    padding-right: 40%;
`

export const SlideImageCard = styled(({ caption, background }) => (
    <div>
        <SlideImageCardComp background={background} />
        {caption && <SlideCaption>{caption}</SlideCaption>}
    </div>
))

export const Section = styled(Container)`
    padding-top: 12px;
    padding-bottom: 10px;
    margin-top: ${({ margintop = 0 }) => margintop}px;
    margin-bottom: ${({ marginbottom = 0 }) => marginbottom}px;
`

export const ArticleWrap = styled.div`
    max-width: ${({ maxwidth }) => maxwidth || 'unset'};
    padding-bottom: 30px;
    @media ${devices.small} {
        padding-left: 0;
        padding-right: 0;
    }
`

export const ArticleTextWrap = styled.div`
    ${margins}
    ${({ nopadding }) => !nopadding && 'padding-right: 60px;'}
    overflow: hidden;
    p:not(:last-of-type) {
        margin-bottom: 18px;
    }
    ul {
        padding: 5px 0px 5px 50px;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: auto !important;
        th, td {
            padding: 5px;
            border: 1px solid #414042;
        }
    }
    @media ${devices.small} {
        padding-right: 0;
    }
    li {
        margin-bottom: 10px;
    }
`

export const Hr = styled.div`
    ${margins}
    width: 100%;
    height: 1px;
    background-color: #a0a0a1;
`

export const ArticleInfo = styled(Block)`
    padding: 36px 25px;
    border: 1px solid #414042;
`

export const IntroText = styled(ArticleTextWrap)`
    padding-bottom: 50px;

    @media ${devices.small} {
        padding-bottom: 20px;
    }
`
