import React, { Fragment } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { toggleMenu } from '../../_actions'
import colors from '../../css/colors.scss'
import {
    devices,
} from '../Common'


export const Header = () => {
    const dispatch = useDispatch()
    const { alert } = useSelector(state => state.content.page)


    const closeMenu = location => e => {
        if (!location || e.metaKey) return
        dispatch(toggleMenu(false))
        document.body.style.overflow = 'visible'
    }

    return (
        <Fragment>
            {!!alert && <AlertBanner dangerouslySetInnerHTML={{ __html: alert }} />}
            <FixedMenu activeAlert={!!alert}>
                <Menu>
                    <HeaderContainer>
                        <Logo href='/' onClick={closeMenu('/')}></Logo>
                        <InnerMenu>
                        </InnerMenu>
                    </HeaderContainer>
                </Menu>
            </FixedMenu>
        </Fragment>
    )
}

const AlertBanner = styled.div`
    z-index: 50;
    background: ${colors.red};
    color: ${colors.white};
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    position: fixed;

    /* * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    a {
        text-decoration: underline;
    } */
`

const Menu = styled.div`
    display: flex;
    align-self: center;
    width: 100%;
    height: 100%;
`

const FixedMenu = styled.header`
    position: fixed;
    width: 100%;
    height: ${({ small }) => small ? 110 : 145}px;
    left: 0;
    top: ${({ activeAlert }) => activeAlert ? 38 : 0}px;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: ${colors.darkGreen};
    transform: ${({ small }) => small ? 'translate3d(0,-65px,0)' : 'translate3d(0,0,0)'};
    transition: transform .2s;

    @media ${devices.small} {
        top: 0;
        height: 70px;
    }
`

const HeaderContainer = styled.div`
    display: flex;
    width: calc(100% - 80px - 80px);
    height: 100%;
    margin: 0 auto;
    position: relative;

    @media ${devices.small} {
        width: 100%;
        margin: 0 20px;
    }
`

const Logo = styled.a`
    display: block;
    background: url('/static/images/sprite.svg') no-repeat 0 0;
`

const InnerMenu = styled.div`
    display: flex;
    margin-left: auto;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 25px 0;
`
