import React from 'react'

import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'


export const Meta = ({ page }) => {
    const content = page
    const description = content.meta_description || content.description || content.content
    const image = content.image || '/static/images/favicon/og-image.png'
    const title = page ? page.meta_title : content.title
    const location = useLocation()

    return (
        <Helmet>
            <meta property='og:url' content={location.pathname} />
            <meta property='og:type' content='website' />
            <meta property='og:site_name' content='Art Attack' />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:image' content={image} />

            <meta name='twitter:card' content='summary_large_image' />
            <meta property='twitter:url' content={location.pathname} />
            <meta property='twitter:title' content={title} />
            <meta property='twitter:description' content={description} />
            <meta property='twitter:image' content={image} />
        </Helmet>
    )
}
