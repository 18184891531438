import React, { useState, useEffect, useRef } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { toggleMenu, listMenu, updateMenuStack, clearPage } from '../../_actions'
import { useOnClickOutside, useWindowSize } from '../../_helpers'
import {
    devices,
    minDevices,
    colors,
    UpperHeader,
    MobileLogo,
    Logo,
    DownArrow,
} from '../Common'
import { BreadCrumb, BreadCrumbBreak } from '../Content'


export const Nav = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    let { menu, menuOpen, menuStack } = useSelector(state => state.content)
    const { width } = useWindowSize()
    const mobile = width < 480
    const [activeImage, setActiveImage] = useState(null)
    const [orientation, setOrientation] = useState('')
    const [showArrow, setShowArrow] = useState(false)
    const [opacity, setOpacity] = useState(1)
    const navRef = useRef(null)

    useOnClickOutside(navRef, () => closePanel())

    useEffect(() => {
        dispatch(listMenu())
    }, [])

    useEffect(() => {
        if (menuStack[menuStack.length - 1]?.title === 'Event Themes') {
            setShowArrow(true)
            setTimeout(() => {
                setOpacity(0)
            }, 5000)
        } else {
            setShowArrow(false)
            setOpacity(1)
        }
    }, [menuStack[menuStack.length - 1]?.title])

    const openMenuPanel = () => {
        dispatch(toggleMenu(!menuOpen))
        document.body.style.overflow = menuOpen ? 'visible' : 'hidden'
    }

    // close individual menuItem
    const changeLocation = location => e => {
        e.preventDefault()
        if (!location) return
        if (location !== history.location.pathname) {
            history.push(location)
            dispatch(clearPage())
        }
        closePanel()
    }

    // Hide the entire side menu
    const closePanel = () => {
        dispatch(toggleMenu(false))
        document.body.style.overflow = 'visible'
    }

    const menuDown = (menuItem) => {
        menuStack.push(menuItem)
        dispatch(updateMenuStack(menuStack))
    }

    const clearStack = () => {
        menuStack = []
        dispatch(updateMenuStack(menuStack))
    }

    const setImage = (item) => {
        setActiveImage(item.first_image)
        setOrientation(item.image_orientation)
    }

    const NavItems = ({ list }) => list.map(item =>
        <NavItem
            key={item.title}
            as={item.children.length === 0 ? Link : ''}
            to={item.children.length === 0 ? item.linked_page : ''}
            onClick={item.children.length === 0 ? changeLocation(item.linked_page) : () => menuDown(item)}
            onMouseEnter={() => item.first_image !== null ? setImage(item) : ''}
        >
            {item.title}
        </NavItem>
    )

    const openMenu = trace => () => dispatch(updateMenuStack(trace))

    return (
        <NavigationWrap open={menuOpen} ref={navRef}>
            <OuterContainer>
                {!menuOpen && <MenuButtonText>Menu</MenuButtonText>}
                <MenuButton open={menuOpen} role='button' onClick={openMenuPanel}>
                    <span />
                    <span />
                    <span />
                </MenuButton>
                {!mobile ? <Logo role='button' onClick={changeLocation('/')} /> : <MobileLogo role='button' onClick={changeLocation('/')} />}
            </OuterContainer>
            <InnerContainer open={menuOpen}>
                <div style={{ height: '28px' }}>
                    {menuStack.length > 0 && <BreadCrumb onClick={clearStack} fontSize={14} clickable>
                        Menu<BreadCrumbBreak>&gt;</BreadCrumbBreak>
                    </BreadCrumb>}
                    {menuStack.slice(0, menuStack.length - 1).map((m, i) =>
                        <BreadCrumb key={`${i}-${m}`} onClick={openMenu(menuStack.slice(0, i + 1))} fontSize={14} clickable>
                            {m.title}<BreadCrumbBreak>&gt;</BreadCrumbBreak>
                        </BreadCrumb>
                    )}
                </div>
                <MenuTitle marginbottom={30} fontSize={mobile ? 35 : 56}>{menuStack[menuStack.length - 1]?.title || 'Menu'}</MenuTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', height: 'inherit' }}>
                    <MenuScroll>
                        <NavItems list={menuStack[menuStack.length - 1]?.children || menu} />
                    </MenuScroll>
                    <ImageContainer orientation={orientation}>
                        {activeImage && <NavImage src={activeImage} />}
                    </ImageContainer>
                    {showArrow && <SeeMore opacity={opacity}>See more &nbsp; <div style={{ transform: 'scale(0.75)' }}><DownArrow /></div></SeeMore>}
                </div>
            </InnerContainer>
        </NavigationWrap>
    )
}

const OuterContainer = styled.div`
    width: 85px;
    position: relative;
    padding-left: 17px;
    flex: 1 0 10%;

    @media ${devices.small} {
        width: 100%;
        min-height: 76px;
        display: flex;
        justify-content: flex-start;
    }

    @media ${devices.medium_portrait} {
        padding-left: 2vw;
    }
`

const InnerContainer = styled.div`
    height: 100%;
    padding-bottom: 40px;
    flex: 1 1 90%;
    display: ${({ open }) => open ? 'block' : 'none'};

    @media ${devices.large} {
        padding: 0 40px;
    }

    @media ${devices.medium} {
        height: 85%;
    }

    @media ${devices.medium_portrait} {
        height: 80%;
    }

    @media ${devices.small} {
        height: 68%;
        padding: 0;
    }
`

const ImageContainer = styled.div`
    flex: 0 1 auto;
    height: 75%;
    /* ${props => props.orientation === 'landscape' && 'align-self: center;'} */

    @media ${devices.small} {
        display: none;
    }
`

const NavImage = styled.img`
    max-width: calc(100% - 14px);
    border: 7px solid white;
    max-height: 100%;
`

const NavigationWrap = styled.nav`
    display: flex;
    background: ${colors.red};
    height: 92vh;
    width: calc(75vw + 50px);
    position: fixed;
    left: 0;
    flex: 1;
    /* padding: 4vh 2vw; */
    padding-top: 4vh;
    padding-bottom: 4vh;
    padding-right: 2vw;
    padding-left: 0;
    background: ${colors.pink};
    color: ${colors.white};

    @media ${devices.small} {
        width: 100%;
        transition: height 0.8s ease;
        height: ${props => props.open ? '100vh' : '5vh'};
        padding: 15px;
        flex-direction: column;
    }

    @media ${minDevices.medium} {
        z-index: 0;
    }
`

const MenuScroll = styled.div`
    height: 90%;
    overflow: auto;
    flex: 1 0 45%;

    /* hide scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`

const NavItem = styled.div`
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${colors.white};
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 80px;
    }

    &:after {
        content: '';
        display: block;
        background: url('/static/images/sprite.svg') no-repeat -85px -55px;
	    width: 23px;
	    height: 20px;
        margin-left: 20px;
        opacity: 0;
        transition: all 0.2s ease-in;
    }
    @media ${minDevices.medium} {
        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }


    @media ${devices.small} {
        font-size: 22px;
        padding-left: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
    }
`

const MenuButtonText = styled.div`
    text-transform: uppercase;
    font-family: pragmatica-extended, sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 10px;

    @media ${devices.small} {
        order: 1;
        font-size: 13px;
        margin: 5px 0 0 10px;
    }
`

const MenuButton = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	width: 40px;
	height: 40px;
	cursor: pointer;

	span {
		display: block;
		width: 40px;
		height: 3px;
		background: ${colors.white};
		margin: 3px 0;
	}

    ${({ open }) => open && `
        span:nth-child(1) {
		    transform: translate(0, 10px) rotate(45deg);
	    }
	    span:nth-child(3) {
		    transform: translate(0, -8px) rotate(-45deg);
	    }
	    span:nth-child(2) {
		    opacity: 0;
        }
    `}

    @media ${devices.small} {
        height: 21px;
        ${({ open }) => open && `
	        span:nth-child(3) {
		        transform: translate(0, -4px) rotate(-45deg);
	        }
        `}
    }

    @media ${devices.medium} {
        margin-left: 0;
    }
`

const MenuTitle = styled(UpperHeader)`
`

const SeeMore = styled.div`
    position: absolute;
    bottom: 28px;
    left: 51%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: pragmatica-extended, sans-serif;
    font-weight: 700;
    font-style: normal;
    transition: opacity 0.3s ease;
    opacity: ${(props) => props.opacity};

    @media ${devices.medium} {
        bottom: 20%;
    }

    @media ${devices.medium_portrait} {
        bottom: 27%;
    }

    @media ${devices.small} {
        /* bottom: 27%; */
        left: 56%;
        right: 8%;
    }
`
