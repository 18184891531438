import React from 'react'

import styled from 'styled-components'

import { devices, margins, colors } from './'


export const Title = styled.div`
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: pragmatica-extended, sans-serif;
    font-weight: 900;
    font-style: normal;
    color: ${colors.white};
    font-size: ${({ fontSize = 44 }) => fontSize}px;
`

export const Header = ({ as = 'div', ...rest }) => {
    return React.createElement(styled(as).attrs(() => rest)`
        font-weight: 700;
        /* max-width: ${props => props.unsetmaxwidth ? 'unset' : '690px'}; */
        /* line-height: 1.2; */
        font-size: ${({ fontSize = 44 }) => fontSize}px;
        ${margins}
        @media ${devices.small} {
           font-size: 25px;
        }
    `)
}

export const UpperHeader = styled.h2`
    font-family: pragmatica-extended, sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: ${({ fontSize = 62 }) => fontSize}px;
    color: ${colors.white};
    /* letter-spacing: 2px; */
    text-transform: uppercase;
    ${margins}
    @media ${devices.small} {
       /* font-size: 26px; */
    }
`

export const UnstyledLink = ({ as = 'a', ...rest }) => {
    const component = styled(as).attrs(() => rest)`
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        &:hover, &:focus, &:active, &:visited {
            color: inherit;
        }
    `

    return React.createElement(component, rest)
}
