import React from 'react'

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { setMenuTrace } from '../../_actions'
import {
    Title,
} from '../Common'

export const BreadCrumbs = ({ title }) => {
    const dispatch = useDispatch()
    const { slug1, slug2, slug3, slug4, slug5, slug6, slug7, slug8 } = useParams()

    const breadCrumbs = [
        slug1 && slug1,
        slug2 && slug2,
        slug3 && slug3,
        slug4 && slug4,
        slug5 && slug5,
        slug6 && slug6,
        slug7 && slug7,
        slug8 && slug8,
    ].filter(e => e)

    const openMenu = trace => () => dispatch(setMenuTrace(trace))

    return (
        <Menu>
            {breadCrumbs.map((m, i) =>
                <BreadCrumb
                    key={`${i}-${m}`}
                    onClick={i !== breadCrumbs.length - 1 ? openMenu(breadCrumbs.slice(0, i + 1)) : () => { }}
                    fontSize={14}
                    clickable={i !== breadCrumbs.length - 1}
                >
                    {i === breadCrumbs.length - 1 ? title : m.replace(/-/g, ' ')}{i < breadCrumbs.length - 1 && <BreadCrumbBreak>&gt;</BreadCrumbBreak>}
                </BreadCrumb>
            )}
        </Menu>
    )
}

const Menu = styled.div`

`

export const BreadCrumb = styled(Title)`
    display: inline-block;
    ${({ clickable }) => clickable ? `
        cursor: pointer;
        opacity: 0.26;
    ` : ''}
`

export const BreadCrumbBreak = styled.span`
    padding: 0 10px;
`
