import { contentTypes } from '../_constants/'
import { createAction, createAsyncAction } from '../_helpers/utils'
import {
    Menu,
    Pages,
    Testimonial,
    Form,
} from '../_services'


export const getPage = createAsyncAction(
    contentTypes.PAGE_DATA_REQUEST,
    contentTypes.PAGE_DATA_SUCCESS,
    Pages.get,
    contentTypes.PAGE_DATA_FAILURE,
)

export const listMenu = createAsyncAction(
    contentTypes.MENU_DATA_REQUEST,
    contentTypes.MENU_DATA_SUCCESS,
    Menu.list
)

export const listTestimonials = createAsyncAction(
    contentTypes.TESTIMONIAL_LIST_REQUEST,
    contentTypes.TESTIMONIAL_LIST_SUCCESS,
    Testimonial.list
)

export const submitFooterForm = createAsyncAction(
    contentTypes.FOOTER_FORM_REQUEST,
    contentTypes.FOOTER_FORM_SUCCESS,
    Form.footer,
    contentTypes.FOOTER_FORM_FAILURE,
)

export const submitEventForm = createAsyncAction(
    contentTypes.EVENT_FORM_REQUEST,
    contentTypes.EVENT_FORM_SUCCESS,
    Form.event,
    contentTypes.EVENT_FORM_FAILURE,
)

export const clearPage = createAction(contentTypes.CLEAR_PAGE)
export const toggleMenu = createAction(contentTypes.TOGGLE_MENU)
export const updateMenuStack = createAction(contentTypes.UPDATE_MENU_STACK)
export const setMenuTrace = createAction(contentTypes.SET_MENU_TRACE)
