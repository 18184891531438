import React, { Suspense } from 'react'

import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import { store, history } from './_helpers'
import { App } from './App'

render(
    <Provider store={ store }>
        <Suspense fallback={<span/>}>
            <Router history={history}>
                <App />
            </Router>
        </Suspense>
    </Provider>,
    document.getElementById('root')
)
