import React from 'react'

import ReactPlayer from 'react-player'
import styled from 'styled-components'

import { colors } from '../../Common'


export const VideoModal = ({ url, showModal, full = false, closeModal }) => {

    return (
        <>
            {full ? (
                <Modal showModal={showModal}>
                    <CloseContainer>
                        <Close onClick={closeModal}>&#xd7;</Close>
                    </CloseContainer>
                    <ReactPlayer url={url} playing={showModal} height='100%' width='100%' controls={true} />
                </Modal>
            ) : (
                    <ReactPlayer
                        url={url}
                        style={full && showModal
                            ? { position: 'fixed', top: '0px' }
                            : { position: 'absolute' }
                        }
                        playing={showModal}
                        height='100%'
                        width='100%'
                        controls={true}
                    />
                )}
        </>
    )
}

const Modal = styled.div.attrs(({ showModal }) => ({
    style: {
        display: showModal ? 'block' : 'none',
    }
}))`
    height: 100%;
    width: 100%;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;

    iframe {
        height: calc(100% - 80px) !important;
    }
`

const CloseContainer = styled.div`
    height: 40px;
    width: 40px;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
`

const Close = styled.div`
    color: ${colors.white};
    position: absolute;
    right: 16px;
    font-size: 35px;
`
