import React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

// import { useWindowSize, useEventListener } from '../../../_helpers'
import {
    colors,
    devices,
    minDevices,
    Button,
} from '../../Common'


export const SimpleBlock = ({ pageBlock }) => (
    <TextBlock>
        <InnerBlock>
            <div style={{ borderBottom: '1px solid white', paddingBottom: '50px' }} dangerouslySetInnerHTML={{ __html: pageBlock.content }} />
            <FormButton as={Link} to={'/event-form/'}>START PLANNING YOUR EVENT</FormButton>
        </InnerBlock>
    </TextBlock>
)

const TextBlock = styled.div`
    font-weight: 900;
    font-style: normal;
    font-size: 44px;
    background: ${colors.red};
    color: ${colors.white};
    width: 100%;
    /* height: 100vh; */
    min-height: 100vh;
    /* padding: 0 20px; */

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media ${devices.small} {
        font-size: 24px;
    }

    @media ${minDevices.small} and ${devices.medium_portrait} {
        font-size: 24px;
    }

    @media ${minDevices.medium_portrait} and ${devices.large} {
        font-size: 30px;
    }
`

const InnerBlock = styled.div`
    width: 70%;
    padding: 80px 0;
    p {
        margin: 0;
    }

    @media ${devices.small} {
        width: 84%;
    }
`

const FormButton = styled(Button)`
    margin-top: 50px;
    font-weight: 700;
    letter-spacing: 1px;
    @media ${devices.medium} {
        font-size: 13px;
        padding: 10px 15px 9px;
    }
`
