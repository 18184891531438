import styled from 'styled-components'

import { devices } from './Layout'

const SpriteBase = styled.div`
    background: url('/static/images/sprite.svg');
    background-repeat: no-repeat;
`

export const RedArrow = styled(SpriteBase)`
    background-position: -77px -10px;
    width: 35px;
	height: 31px;
`

export const Logo = styled(SpriteBase)`
    background-position: -1px -48px;
    width: 76px;
	height: 190px;
    cursor: pointer;
    position: absolute;
    top: 38%;
    transform: scale(1.3);
    transform-origin: left;
`

export const HomeLogo = styled(SpriteBase)`
    background-position: -200px -22px;
    height: 128px;
	width: 312px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const MobileLogo = styled(Logo)`
    transform: rotate(90deg) scale(0.8);
    top: 60px;
    right: 30px;
    transform-origin: 100% 0;
`

export const RightArrow = styled(SpriteBase)`
    background: url('/static/images/sprite.svg') no-repeat -85px -55px;
	width: 23px;
	height: 20px;
`

export const LeftArrow = styled(RightArrow)`
    transform: rotate(180deg);
    margin-right: 10px;

    @media ${devices.small} {
        margin-right: 0;
        transform: rotate(180deg) scale(0.8);
    }
`

export const DownArrow = styled(RightArrow)`
    transform: rotate(90deg) scale(0.75);
    margin-right: 10px;
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 2;

    @keyframes bounce {
	    0%, 100%, 20%, 50%, 80% {
	    	transform: translateY(0) rotate(90deg);
	    }
	    40% {
	    	transform: translateY(-30px) rotate(90deg);
	    }
	    60% {
	    	transform: translateY(-15px) rotate(90deg);
	    }
    }

    animation-name: bounce;
    -moz-animation-name: bounce;
`

export const ThumbnailsIcon = styled(SpriteBase)`
    background-position: -127px -74px;
    width: 21px;
    height: 14px;
    margin-right: 10px;
    cursor: pointer;
`

export const VideoPlayButton = styled(SpriteBase)`
    background-position: -89px -205px;
    width: 80px;
	height: 79px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const SmallArrow = styled(SpriteBase)`
    background-position: -104px -173px;
    width: 13px;
	height: 11px;
    margin-left: 30px;
`

export const CarouselNext = styled(SpriteBase)`
    background-position: -125px -2px;
    width: 26px;
    height: 44px;
    margin-left: 20px;
    cursor: pointer;

    @media ${devices.small} {
        position: absolute;
        top: 45%;
        right: 10px;
        transform: scale(0.8);
    }
`

export const CarouselPrev = styled(CarouselNext)`
    transform: rotate(180deg);
    margin-left: 0;
    margin-right: 20px;

    @media ${devices.small} {
        left: 10px;
        z-index: 1;
        transform: rotate(180deg) scale(0.8);
    }
`