import axios from 'axios'

export const Pages = {
    get: url => axios.get(`/api/page/${encodeURIComponent(encodeURIComponent(`${url}${url[url.length - 1] !== '/' ? '/' : ''}`))}/`),
}

export const Menu = {
    list: () => axios.get(`/api/menu/`),
}

export const Testimonial = {
    list: () => axios.get(`/api/testimonial/`),
}

export const Form = {
    event: data => axios.post(`/api/form/event/`, JSON.stringify(data)),
    footer: data => axios.post(`/api/form/footer/`, JSON.stringify(data)),
}
