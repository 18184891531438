import React from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
    Container,
    Row,
    devices,
    colors,
    Button,
} from '../Common'

export const Footer = () => {
    return (
        <FooterWrap>
            <FooterContainer>
                <FooterRow basis={30}>
                    <div>
                        <FooterHeading>Info</FooterHeading>
                        <Address>
                            626 West Cavalcade<br />
                            Houston, TX 77009<br />
                            Phone: <a href="tel:7138613331">713.861.3331</a><br />
                            <br />
                            Visit Our Warehouse<br />
                            — By appointment only
                        </Address>
                        <Icons>
                            <Instagram aria-label='Instagram' href='https://www.instagram.com/artattackeventco/' target='_blank' rel='noopener noreferrer' />
                            <Facebook aria-label='Facebook' href='https://www.facebook.com/artattackco/' target='_blank' rel='noopener noreferrer' />
                        </Icons>
                    </div>
                </FooterRow>
                <FooterRow basis={20}>
                    <FooterHeading>Get Started</FooterHeading>
                    <p style={{ margin: 0 }}>Please fill out our event form to begin planning your next event.</p>
                    <br />
                    <EventFormLink as={Link} to={'/event-form/'}>EVENT FORM</EventFormLink>
                </FooterRow>
            </FooterContainer>
        </FooterWrap>
    )
}

const FooterWrap = styled.footer`
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    background: ${colors.red};
    color: ${colors.white};

    @media ${devices.small} {
        padding-bottom: 100px;
    }
`

const Address = styled.div`
    a {
        text-decoration: none;
        color: ${colors.white};
    }
`

const FooterContainer = styled(Container)`
    display: flex;
    justify-content: flex-start;
    width: auto;

    @media ${devices.small} {
        flex-direction: column-reverse;
    }

    @media ${devices.medium_portrait} {
        justify-content: space-between;
    }
`

const FooterHeading = styled.div`
    text-transform: uppercase;
    margin-bottom: 5px;
    font-family: pragmatica-extended, sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 14px;
`

const EventFormLink = styled(Button)`
    position: relative;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 1px;
    color: ${colors.white};
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    @media ${devices.medium} {
        font-size: 13px;
        padding: 10px 15px 9px;
    }
`

const FooterRow = styled(Row)`
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: ${({ basis }) => basis}%;
    @media ${devices.small} {
        margin-bottom: 30px;
    }

    @media ${devices.medium_portrait} {
        flex-basis: 40%;
    }
`

const Icons = styled.div`
    margin-top: 20px;
`

const SocialIcon = styled.a`
    background-image: url('/static/images/sprite.svg');
    background-repeat: no-repeat;
    background-position-y: -125px;
    margin-right: 10px;
    display: inline-block;
`

const Facebook = styled(SocialIcon)`
    background-position-x: -100px;
    width: 23px;
	height: 22px;
`

const Instagram = styled(SocialIcon)`
    background-position: -127px -100px;
	width: 23px;
	height: 22px;
`


