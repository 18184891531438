import styled, { css } from 'styled-components'


export const devices = {
    tiny: '(max-width: 480px)',
    small: '(max-width: 740px)',
    medium_portrait: '(max-width: 940px)',
    medium: '(max-width: 1040px)',
    large: '(max-width: 1340px)',
    extralarge: '(max-width: 1420px)',
}

export const minDevices = {
    tiny: '(min-width: 480px)',
    small: '(min-width: 740px)',
    medium_portrait: '(min-width: 940px)',
    medium: '(min-width: 1040px)',
    large: '(min-width: 1340px)',
    extralarge: '(min-width: 1420px)',
}

export const margins = css`
    margin-top: ${({ margintop = 0 }) => margintop}px;
    margin-bottom: ${({ marginbottom = 0 }) => marginbottom}px;
    margin-left: ${({ marginleft = 0 }) => marginleft}px;
    margin-right: ${({ marginright = 0 }) => marginright}px;

    @media ${devices.small} {
        ${({ margintop }) => margintop && `margin-top: ${margintop / 2}px;`}
        ${({ marginbottom }) => marginbottom && `margin-bottom: ${marginbottom / 2}px;`}
    }
`

export const paddings = css`
    padding-top: ${({ paddingtop = 0 }) => paddingtop}px;
    padding-bottom: ${({ paddingbottom = 0 }) => paddingbottom}px;

    @media ${devices.small} {
        ${({ paddingtop }) => paddingtop ? `padding-top: ${paddingtop / 2}px;` : ''}
        ${({ paddingbottom }) => paddingbottom ? `padding-bottom: ${paddingbottom / 2}px;` : ''}
    }
`

export const Container = styled.section`
    margin-left: auto;
    margin-right: auto;
    max-width: ${({ maxwidth }) => maxwidth || '1400px'};
    width: 100%;
    padding-bottom: 35px;
    padding-top: 35px;
    
    @media (max-width: 1450px) {
        padding-left: 50px;
        padding-right: 50px;
    }
    
    @media ${devices.small} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const Image = styled.img`
    position: relative;
    display: block;
    border: 7px solid white;
`

const tabletWidthCss = css`
    @media ${devices.tablet} {
        width: ${({ tabletWidth = 100 }) => tabletWidth}%;
    }
`

const GridColumn = styled.div`
    position: relative;
    display: inline-block;
    width: ${({ width = 100 }) => width}%;
    ${({ tabletWidth }) => tabletWidth ? tabletWidthCss : ''}
    @media ${devices.small} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    ${margins}
`

const tabletColumnsCss = css`
    @media ${devices.tablet} {
        width: ${({ columnsTablet = 1 }) => 100 / columnsTablet}%;
    }
`

export const columnsCss = css`
    & > ${GridColumn} {
        width: ${({ columns = 1, totalmargin }) => totalmargin ? `calc(${100 / columns}% - ${Math.ceil(totalmargin / columns)}px)` : `${100 / columns - 2}%`};
        ${({ columnsTablet }) => columnsTablet && tabletColumnsCss}
        @media ${devices.small} {
            width: 100%;
        }
    }
`

export const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    ${props => props.width ? 'width:' + props.width + ';' : ''}
    ${margins}
    ${({ columns }) => columns ? columnsCss : ''}
`

Grid.Column = GridColumn

export const ArticleWrapColumn = styled(Grid.Column)`
    @media (min-width: 800px) {
        width: 100%;
    }
`

export const ContentWrap = styled.div`
    ${({ has_menu }) => has_menu ? `
        width: calc(100% - 220px - 60px);
    ` : ''}

    @media ${devices.small} {
        width: 100%;
        padding: 0 20px;
    }
`

export const Hero = styled.div`
    height: 100vh;
    width: 100%;
    background: center / cover no-repeat url('/static/images/hero/home-hero.png');
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;

    @media ${devices.small} {
        background: center / cover no-repeat url('/static/images/hero/mobile-hero.png');
        height: 94vh;
        background-size: 101%;
    }

    @media ${minDevices.small} and ${devices.medium_portrait} {
        background: center / cover no-repeat url('/static/images/hero/tablet-hero.png');
    }
`

export const StickyImage = styled(Hero)`
    background: center / cover no-repeat url(${props => props.background});
    background-attachment: fixed;
    /* 
        background-attachment fixed does not work on mobile 
        and prevents image from loading.
        Instead we make this block transparent
        and set the background on the main element
    */
    @media ${devices.small} {
        background: transparent;
    }

    @media ${minDevices.small} and ${devices.medium} {
        background-attachment: scroll;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;

    ${props => props.margintop && `
        margin-top: ${props.margintop}px;
    `}

    ${props => props.marginbottom && `
        margin-bottom: ${props.marginbottom}px;
    `}

    @media ${devices.small} {}
`

export const Button = styled.button`
    display: table;
    ${margins}
    padding: 10px 20px 9px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 25px;
    user-select: none;

    font-family: pragmatica-extended, sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 17px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    white-space: nowrap;
    cursor: pointer;
    width: max-content;
    outline: none;

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }

    @media ${devices.medium} {
        padding: 15px 25px 14px;
    }
`
