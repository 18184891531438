/* eslint-disable no-control-regex */
export const phonenumber = (number) => !!number.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
export const email = (str) =>
    !!str.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    )
const requiredMessage = 'This field is required'

export const validateEventForm = (values) => {
    const errors = {}

    if (!values.email) errors.email = requiredMessage
    else if (!email(values.email)) errors.email = 'Invalid email address'

    if (!values.full_name) errors.full_name = requiredMessage

    if (!values.phone_number) errors.phone_number = requiredMessage
    else if (!phonenumber(values.phone_number)) errors.phone_number = 'Invalid phone number'

    if (!values.event_date) errors.event_date = requiredMessage

    if (!values.event_location) errors.event_location = requiredMessage

    if (!values.event_time) errors.event_time = requiredMessage

    if (!values.event_theme) errors.event_theme = requiredMessage

    if (!values.number_of_guests) errors.number_of_guests = requiredMessage

    if (!values.budget) errors.budget = requiredMessage

    if (!values.event_type) errors.event_type = requiredMessage

    if (!values.interest) errors.interest = requiredMessage

    if (!values.table_count) errors.table_count = requiredMessage

    return errors
}
