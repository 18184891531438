export const cookies = {
    getCookie,
}

function getCookie(name) {
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].includes(`${name}=`)) {
                return cookies[i].split('=')[1].trim()
            }
        }
    }
}
