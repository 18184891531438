import React, { lazy, useEffect } from 'react'

import ReactGA from 'react-ga'
import Modal from 'react-modal'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { getPage } from './_actions'
import {
    Footer,
    Nav,
} from './Components'
import './css/App.scss'
import { devices, colors, minDevices } from './Components/Common'

const Home = lazy(() => import(/* webpackChunkName: "Home" */ './Components/Content/Home'))
const IEModal = lazy(() => import(/* webpackChunkName: "IEModal" */ './Components/Global/IEModal'))
const PageView = lazy(() => import(/* webpackChunkName: "PageView" */ './Components/Content/PageView'))
const Testimonials = lazy(() => import(/* webpackChunkName: "Testimonials" */ './Components/Content/Testimonials'))
const EventForm = lazy(() => import(/* webpackChunkName: "EventForm" */ './Components/Content/EventForm'))

Modal.setAppElement('#root')

ReactGA.initialize('UA-2686154-12', {
    debug: false,
})

const ScrollTopRoute = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById('main').scrollTop = 0
    }, [pathname])

    return null
}

const Routes = () => {
    const dispatch = useDispatch()
    const { ie } = useSelector(state => state.content, shallowEqual)
    const { pathname } = useLocation()

    useEffect(() => {
        dispatch(getPage(pathname))
        ReactGA.pageview(location.pathname + location.search)
    }, [pathname])

    return (
        <>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/testimonials/' component={Testimonials} />
                <Route exact path='/event-form/' component={EventForm} />
                <Route path='/:slug1/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?/:slug7?/:slug8?' component={PageView} />
            </Switch>
            {ie ? <IEModal /> : ''}
        </>
    )
}

export const App = () => {
    const { menuOpen, page } = useSelector(state => state.content, shallowEqual)
    return (
        <MainPage>
            <Route path='*' component={Nav} />
            <ScrollTopRoute />
            <PageWrap background={page.hero_image} menu={menuOpen} id="main">
                <Routes />
                <Route path='*' component={Footer} />
            </PageWrap>
        </MainPage>
    )
}

const PageWrap = styled.main`
    flex: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    transition: transform 0.8s ease;
    background: ${colors.black};

    @media ${minDevices.small} {
        /* TODO background-attachment doesn't work on firefox with this  */
        transform: translate(${({ menu }) => menu ? 'calc(78vw + 29px)' : '75px'}, 0) ${props => props.menu && '!important'};
        width: calc(100vw - 75px);
        box-shadow: -5px 0 15px rgba(0,0,0,0.4);
    }

    @media ${devices.small} {
        z-index: 1;
        transform: translate(0, ${({ menu }) => menu ? '100vh' : '50px'});
        width: 100vw;
        height: 95vh;
        overflow-y: scroll;
        position: absolute;
        box-shadow: 0 -5px 15px rgba(0,0,0,0.4);
        /* homepage fixed bg workaround */
        ${props => props.background && `background: center / cover no-repeat url(${props.background});`}
    }
`

const MainPage = styled.div`
    display: ${props => props.ie ? 'block' : 'flex'};
    flex-direction: column;
    background: black;

    @media ${minDevices.large} {
        ${({ menu }) => !menu ? `
        nav:hover + main {
             transform: translate(120px, 0);
        }
    ` : ''}
    }
`
