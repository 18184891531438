import React from 'react'

import styled from 'styled-components'


export const Loading = ({ marginbottom }) => (
    <LoadingSpinner marginbottom={marginbottom}>
        <Spinner className='spinner'>
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
            <SpinnerIcon />
        </Spinner>
    </LoadingSpinner>
)

const LoadingSpinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: ${({ marginbottom = 0 }) => marginbottom}px;

    @keyframes spinner {
        0% {
            opacity: 0.5;
        }

        100% {
            opacity: 0;
        }
    }
`

const Spinner = styled.div`
    position: relative;
    width: 100px !important;
    height: 100px !important;
    transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
`

const SpinnerIcon = styled.div`
    background: white;
    left: 94px;
    top: 48px;
    position: absolute;
    animation: spinner linear 1s infinite;
    width: 12px;
    height: 24px;
    border-radius: 40%;
    transform-origin: 6px 52px;

    &:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -0.916666666666667s;
    }

    &:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -0.833333333333333s;
    }

    &:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.75s;
    }

    &:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.666666666666667s;
    }

    &:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.583333333333333s;
    }

    &:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.5s;
    }

    &:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.416666666666667s;
    }

    &:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.333333333333333s;
    }

    &:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.25s;
    }

    &:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.166666666666667s;
    }

    &:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.083333333333333s;
    }

    &:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
    }

`
