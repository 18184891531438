import React, { useState } from 'react'

import styled from 'styled-components'

import { Hero, VideoPlayButton, devices } from '../../Common'
import { VideoModal } from './VideoModal'

export const VideoBlock = ({ pageBlock }) => {
    const [showModal, setShowModal] = useState(false)

    const openModal = () => setShowModal(true)
    const closeModal = () => setShowModal(false)

    return (
        <>
            <ThumbnailHero background={pageBlock.image}>
                <VideoPlayButton onClick={openModal} />
                <VideoModal
                    url={pageBlock.video_url}
                    showModal={showModal}
                    closeModal={closeModal}
                    full={true} />
            </ThumbnailHero>
        </>
    )
}

const ThumbnailHero = styled(Hero)`
    height: calc(56.25vw - 40px);
    background: center / cover no-repeat url(${props => props.background});

    @media ${devices.mobile} {
        height: 94vh;
    }
`
