import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

import rootReducer from '../_reducers'

let middleware = [thunkMiddleware]

if (process.env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger()
    middleware = [...middleware, loggerMiddleware]
}

export const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
)
