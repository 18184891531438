import React from 'react'

import styled from 'styled-components'

import {
    SimpleBlock,
    SimpleCarousel,
    VideoBlock,
} from '../'
import { useWindowSize } from '../../_helpers'
import { StickyImage } from '../Common'
import { Instagram } from './Blocks/Instagram'


export const PageBlock = ({ pageBlock }) => {
    const { format, subblocks } = pageBlock
    const { width } = useWindowSize()
    const mobile = width < 480

    return (
        <DynamicWidthBlock id={pageBlock.hash}>
            {pageBlock.hash_url ? <div id={pageBlock.hash_url.toLowerCase().replace(/ /g, '_')} /> : ''}
            {format === 'CAROUSEL' ? <SimpleCarousel slides={pageBlock.carousel} content={pageBlock.content} smallTitle={pageBlock.small_title} mobile={mobile} /> : ''}
            {format === '1-COLUMN' ? <SimpleBlock pageBlock={pageBlock} /> : ''}
            {format === 'INSTAGRAM' ? <Instagram slides={subblocks} mobile={mobile} /> : ''}
            {format === 'VIDEO' ? <VideoBlock pageBlock={pageBlock} /> : ''}
            {format === 'STICKY' ? <StickyImage background={pageBlock.image} /> : ''}
        </DynamicWidthBlock>
    )
}

const DynamicWidthBlock = styled.div`
`
