import { contentTypes } from '../_constants'
import { BrowserDetect } from '../_helpers/utils'

const initialState = {
    page: {},
    menu: [],
    ie: BrowserDetect() === 'Explorer',
    menuOpen: false,
    notFound: false,
    eventMessage: '',
    eventError: '',
    footerMessage: '',
    footerError: '',
    gallery: [],
    testimonials: [],
    menuStack: [],
    menuTrace: [],
}

export function content(state = initialState, action) {
    switch (action.type) {
        case contentTypes.PAGE_DATA_SUCCESS:
            return {
                ...state,
                page: action.payload,
                notFound: false,
            }
        case contentTypes.PAGE_DATA_FAILURE:
            return {
                ...state,
                notFound: true,
            }
        case contentTypes.MENU_LINKS_SUCCESS:
            return {
                ...state,
                menuLinks: action.payload,
            }
        case contentTypes.CLEAR_PAGE:
            return {
                ...state,
                page: {},
            }
        case contentTypes.FOOTER_FORM_SUCCESS:
            return {
                ...state,
                footerMessage: action.payload,
                footerError: '',
            }
        case contentTypes.FOOTER_FORM_FAILURE:
            return {
                ...state,
                footerMessage: '',
                footerError: action.payload,
            }
        case contentTypes.EVENT_FORM_SUCCESS:
            return {
                ...state,
                eventMessage: action.payload,
                eventError: '',
            }
        case contentTypes.EVENT_FORM_FAILURE:
            return {
                ...state,
                eventMessage: '',
                eventError: action.payload,
            }
        case contentTypes.TOGGLE_MENU:
            return {
                ...state,
                menuOpen: action.payload,
            }
        case contentTypes.MENU_DATA_SUCCESS:
            return {
                ...state,
                menu: action.payload,
            }
        case contentTypes.TESTIMONIAL_LIST_SUCCESS:
            return {
                ...state,
                testimonials: action.payload,
            }
        case contentTypes.UPDATE_MENU_STACK:
            return {
                ...state,
                menuStack: action.payload,
            }
        case contentTypes.SET_MENU_TRACE:
            return {
                ...state,
                menuStack: getMenu(action.payload, state.menu),
                menuOpen: true,
            }
        default:
            return state
    }
}

const getMenu = (trace, menu, url='') => {
    if (!trace.length) return []
    url += `${url ? '' : '/'}${trace[0]}/`
    let item
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].linked_page === url) {
            item = menu[i]
            break
        }
    }
    return [item, ...getMenu(trace.slice(1), item.children, url)]
}
