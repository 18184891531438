import styled from 'styled-components'

import { devices, } from '../Common'
import { colors } from './Colors'

export const FormError = styled.div`
    display: block;
    color: ${({ color = colors.red }) => color};
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0px;
`

export const Input = styled.input`
    background: ${colors.inputBlack};
    border: none;
    font-size: 20px;
    margin-bottom: 5px;
    outline: none;
    color: ${colors.white};
    padding: 15px;
    width: auto;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;

    &[type='date']:before {
        content: attr(placeholder) !important;
        color: rgba(255, 255, 255, 0.5);
        margin-right: 0.5em;
    }

    &[type='date']:focus:before,
    &[type='date']:valid:before {
        content: '';
    }

    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    @media ${devices.medium_portrait} {
        font-size: 17px;
    }
`

export const Textarea = styled.textarea`
    background: ${colors.inputBlack};
    border: none;
    font-size: 20px;
    margin-bottom: 5px;
    color: ${colors.white};
    resize: none;
    outline: none;
    padding: 10px 15px;
    height: 100px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;

    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    @media ${devices.medium_portrait} {
        font-size: 17px;
    }
`

export const BaseLabel = styled.label`
    color: ${colors.white};
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;

    @media ${devices.small} {
        margin-bottom: 24px;
    }

    input[type="checkbox"], input[type="radio"] {
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &[type="checkbox"]:checked ~ span {
            background-color: ${colors.red};
        }

        &:checked ~ span:after {
            display: block;
        }
    }

    span {
        position: absolute;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid ${colors.white};
        display: flex;
        align-items: center;
        justify-content: center;

        @media ${devices.small} {
            width: 15px;
            height: 15px;
        }
    }
`

export const MultiChoiceInput = styled.span`
    border-radius: 50%;

    &:after {
        content: "";
        display: none;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        margin-bottom: 3px;

        @media ${devices.small} {
            width: 3px;
            height: 8px;
            margin-bottom: 1px;
        }
    }
`

export const RadioInput = styled.span`
    border-radius: 50%;

    &:after {
        content: "";
        display: none;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: ${colors.red};

        @media ${devices.small} {
            width: 9px;
            height: 9px;
        }
    }
`