import React from 'react'

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import styled from 'styled-components'

import {
    UpperHeader,
    devices,
    colors,
    minDevices,
    // Container,
    // UnstyledLink,
} from '../../Common'

export const Instagram = ({ slides, mobile }) => {
    const slidesToShow = mobile ? 2 : 4
    return (
        <CarouselContainer>
            <ImageLayer>
                {!mobile && <SmallHeading fontSize={14}>Instagram</SmallHeading>}
                {!mobile && <CarouselHeader as={'a'} href='https://www.instagram.com/artattackeventco/' target='_blank' rel='noopener noreferrer' marginbottom={30} fontSize={23}>@artattackeventco</CarouselHeader>}
                <StyledCarouselProvider
                    totalSlides={slides.length}
                    visibleSlides={slidesToShow}
                    dragEnabled={false}
                    step={slidesToShow}
                    isIntrinsicHeight={true}
                >
                    {!mobile && <Previous />}
                    {mobile &&
                        <MobileArrows>
                            <div>
                                <SmallHeading fontSize={14}>Instagram</SmallHeading>
                                <CarouselHeader fontSize={16}>@artattackeventco</CarouselHeader>
                            </div>
                            <div>
                                <Previous />
                                <Next />
                            </div>
                        </MobileArrows>
                    }
                    <SliderWrap>
                        {slides.map((slide, i) => (
                            <Slide key={i} index={i}>
                                <a href={slide.post_url} target='_blank' rel='noopener noreferrer' style={{ display: 'block' }}>
                                    <StyledImage key={slide.image} src={slide.image} alt={slide.alt_text} tag={'div'} />
                                </a>
                            </Slide>
                        ))}
                    </SliderWrap>
                    {!mobile && <Next />}
                </StyledCarouselProvider>
            </ImageLayer>
        </CarouselContainer>
    )
}

const SliderWrap = styled(Slider)`
    div,ul,li {
        outline: none !important;
    }
`

const SmallHeading = styled(UpperHeader)`
    margin-left: 3%;
    margin-bottom: 5px;
    color: ${colors.red};

    @media ${devices.small} {
        margin-left: 0;
    }
`

const CarouselHeader = styled(UpperHeader)`
    margin-left: 3%;
    text-decoration: none;
    color: ${colors.white} !important;

    @media ${devices.small} {
        margin-left: 0;
    }
`

const CarouselContainer = styled.div`
    background-color: ${colors.black};
`

const ImageLayer = styled.div`
    background: url('/static/images/instagram_bg.png') center/cover no-repeat;
    padding: 60px;

    @media ${minDevices.small} and ${devices.medium_portrait} {
        padding: 30px;
    }

    @media ${devices.small} {
        padding: 30px 10px;
    }

`

const StyledImage = styled(Image)`
    border: 7px solid white;
    padding-bottom: 100%;
    @media ${devices.small} {
        border-width: 3px;
    }
`

const StyledCarouselProvider = styled(CarouselProvider)`
    @media ${minDevices.small} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
    }

    .carousel__slider--horizontal {
        flex: 0 1 90%;

        .carousel__inner-slide {
            padding-left: 10px;
            padding-right: 10px;
        }

        @media ${devices.small} {
            .carousel__inner-slide {
            }
        }
    }

    .carousel__next-button,
    .carousel__back-button {
        border: none;
        z-index: 1;
        outline: none !important;
        &:disabled {
            opacity: 0;
            cursor: default;
        }
    }

    .carousel__back-button {
        transform: rotate(180deg);
    }

    .carousel__next-button {
    }

    @media ${devices.small} {
        .carousel__back-button {
            transform: rotate(180deg) scale(0.5);
        }

        .carousel__next-button {
            transform: scale(0.5);
        }
    }
`

const MobileArrows = styled.div`
    display: flex;
    margin: 0 10px 20px;
    align-items: center;
    justify-content: space-between;
`

const Previous = styled(ButtonBack)`
    width: 35px;
	height: 31px;
    background: url('/static/images/sprite.svg') no-repeat -77px -10px;

    @media ${devices.small} {
        margin-right: 10px;
    }
`

const Next = styled(ButtonNext)`
    width: 35px;
	height: 31px;
    background: url('/static/images/sprite.svg') no-repeat -77px -10px;
`