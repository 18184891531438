export const scrollTo = (Y, duration, element = window) => {
    // const startY = window.pageYOffset
    const startY = element.scrollTop
    const diff = Y - startY
    let start

    const step = timestamp => {
        if (!start) {
            start = timestamp
        }
        const time = timestamp - start
        const percent = Math.min(time / duration, 1)
        element.scrollTo(0, startY + diff * percent)
        if (time < duration) {
            window.requestAnimationFrame(step)
        }
    }
    window.requestAnimationFrame(step)
}

export const scrollToAnchor = (offset=100) => {
    const hashParts = window.location.hash.split('#')
    if (hashParts.length > 1) {
        const hash = hashParts.slice(-1)[0]
        const element = document.getElementById(hash)
        if (element) scrollTo(element.offsetTop - offset, 600)
    }
}

export const scrollTimer = (offset, count=10) => {
    count--
    if (count < 0) return

    setTimeout(() => {
        try {
            scrollToAnchor(offset)
        } catch (e) {
            scrollTimer(offset, count)
        }
    }, 1000)
}
