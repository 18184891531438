export const createConstants = (...consts) => consts.reduce(
    (obj, constant) => ({ ...obj, [constant]: constant}), {}
)

export const createAction = (type) => (payload) => ({ type, payload })

export const createAsyncAction = (req, succ, apiMethod, fail) => {
    return (params) => {
        const request = createAction(req)
        const success = createAction(succ)
        let failure
        if (fail) failure = createAction(fail)

        return (dispatch) => {
            dispatch(request)
            apiMethod(params)
                .then(res => dispatch(success(res.data)))
                .catch(err => {
                    if (err.response && failure) {
                        if (err.response.data) {
                            return dispatch(failure(err.response.data.error))
                        } else if (err.response.status === 404) {
                            return dispatch(failure(err.response))
                        } else {
                            return dispatch(failure(err.response.status))
                        }
                    }
                }
            )
        }
    }
}

export const scrollTo = (Y, duration) => {
    const startY = window.pageYOffset
    const diff = Y - startY
    let start

    const step = (timestamp) => {
        if (!start) {
            start = timestamp
        }
        const time = timestamp - start
        const percent = Math.min(time/duration, 1)
        window.scrollTo(0, startY + diff * percent)
        if (time < duration) {
            window.requestAnimationFrame(step)
        }
    }
    window.requestAnimationFrame(step)
}

export const BrowserDetect = () => {
    const searchString = (data) => {
        for (let i = 0; i < data.length; i++) {
            const dataString = data[i].string;
            if (dataString.indexOf(data[i].subString) !== -1) return data[i].identity
        }
    }

    const dataBrowser = [
        {string: navigator.userAgent, subString: "Edge", identity: "MS Edge"},
        {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
        {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
        {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
        {string: navigator.userAgent, subString: "Opera", identity: "Opera"},
        {string: navigator.userAgent, subString: "OPR", identity: "Opera"},
        {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
        {string: navigator.userAgent, subString: "Safari", identity: "Safari"}
    ]

    const browser = searchString(dataBrowser) || "Other"
    return browser
}

export const stripTags = str => str.replace(/(<([^>]+)>)/ig, '')
