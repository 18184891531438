import { createConstants } from '../_helpers/utils'

export const contentTypes = createConstants(
    'PAGE_DATA_REQUEST',
    'PAGE_DATA_SUCCESS',
    'PAGE_DATA_FAILURE',
    'MENU_DATA_REQUEST',
    'MENU_DATA_SUCCESS',
    'TESTIMONIAL_LIST_REQUEST',
    'TESTIMONIAL_LIST_SUCCESS',
    'CLEAR_PAGE',
    'TOGGLE_MENU',
    'EVENT_FORM_REQUEST',
    'EVENT_FORM_SUCCESS',
    'EVENT_FORM_FAILURE',
    'FOOTER_FORM_REQUEST',
    'FOOTER_FORM_SUCCESS',
    'FOOTER_FORM_FAILURE',
    'CLEAR_FORMS',
    'UPDATE_MENU_STACK',
    'SET_MENU_TRACE',
)
