import React from 'react'

import styled from 'styled-components'

import { Meta } from '../Common'

export const NotFound = () => (
    <NotFoundContainer>
        <Meta page={{ title: 'Not Found | Art Attack' }} />
        <h2>Not Found</h2>
        <p>The page you&apos;re looking for does not exist.</p>
    </NotFoundContainer>
)

const NotFoundContainer = styled.div`
    height: calc(100vh - 435px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
