import React, { useState, useRef, useEffect } from 'react'

import { Carousel as CarouselComp } from 'react-responsive-carousel'
import styled from 'styled-components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { BreadCrumbs } from '../..'
import { useEventListener } from '../../../_helpers'
import {
    devices,
    colors,
    CarouselNext,
    CarouselPrev,
    // Title,
    ThumbnailsIcon,
    minDevices,
} from '../../Common'

const Slide = ({ image }) => {
    const { image: img, alt_text, height, width } = image
    const [slideWidth, setSlideWidth] = useState(width)
    const card = useRef()
    const landscape = width > height
    const paddingBottom = landscape ? height / width * 100 : width / height * 100

    const updateWidth = () => {
        const ratio = width / height
        if (card.current) {
            const width = card.current.offsetHeight
            setSlideWidth(Math.round(ratio * width))
        }
    }

    useEffect(() => {
        updateWidth()
    }, [card])

    useEventListener('resize', updateWidth)

    return <ImageCard ref={card} paddingBottom={paddingBottom > 56.26 ? 56.26 : paddingBottom} width={slideWidth} background={img} alt={alt_text} data-id={image.image_id} />
}

export const SimpleCarousel = ({ slides, close, activeImage, mobile, title }) => {
    const [activeSlide, setActiveSlide] = useState(activeImage)
    const [caption, setCaption] = useState(slides[activeImage].author)
    const [description, setDescription] = useState(slides[activeImage].description)

    const handleChange = id => {
        setActiveSlide(id)
        setCaption(slides[id].author)
        setDescription(slides[id].description)
    }

    const next = () => setActiveSlide(activeSlide + 1)
    const prev = () => setActiveSlide(activeSlide - 1)

    return (
        <CarouselContainer>
            <CarouselFlex>
                <CarouselPrev onClick={prev} role='button' aria-label='Previous slide' />
                <CarouselCard>
                    <BreadCrumbs title={title} mobile={mobile} />
                    <StyledCarousel
                        showStatus={false}
                        showArrows={false}
                        showThumbs={false}
                        showIndicators={false}
                        infiniteLoop={slides.length > 1}
                        stopOnHover={false}
                        interval={5000}
                        transitionTime={0}
                        autoPlay={false}
                        selectedItem={activeSlide}
                        onChange={handleChange}
                        // dynamicHeight={true}
                        useKeyboardArrows={true}
                        autoFocus={true}
                    >
                        {slides.map(image => <Slide key={image.image} image={image} />)}
                    </StyledCarousel>
                </CarouselCard>
                <CarouselNext onClick={next} role='button' aria-label='Next slide' />
            </CarouselFlex>
            <InfoBox>
                <PhotoInfo>
                    <Caption>{description || ' '}</Caption>
                    <Credit>{caption || ' '}</Credit>
                </PhotoInfo>
                <ThumbnailButton onClick={() => close(activeSlide)}>
                    <ThumbnailsIcon />Show Thumbnails
                </ThumbnailButton>
            </InfoBox>
        </CarouselContainer>
    )
}

const ThumbnailButton = styled.div`
    margin-top: 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${colors.white};
    font-family: pragmatica-extended, sans-serif;
    font-weight: 900;
    font-size: 12px;
    cursor: pointer;
    letter-spacing: 0.5px;

    @media ${devices.small} {
        font-size: 9px;
        margin-top: 15px;
    }
`

const CarouselCard = styled.div`
    @media ${devices.small} {
        width: 100%;
    }

    @media ${minDevices.small} {
        width: 84%;
    }

    @media ${minDevices.large} {
        width: 90%;
    }
`

const InfoBox = styled(CarouselCard)`
    margin: 0 auto;
    margin-top: 20px;
`

const CarouselContainer = styled.div`
    /* position: relative; */
    /* padding-top: 50px; */
    padding-bottom: 50px;

    @media ${devices.small} {
        align-self: flex-start;
        padding-left: 20px;
        padding-right: 20px;
    }

    @media ${minDevices.small} {
        padding-left: 48px;
        padding-right: 48px;
    }
`

const StyledCarousel = styled(CarouselComp)`
    .slide {
        background: transparent;
        transition: opacity 600ms ease-in-out;
        opacity: 1;
        display: flex;
        justify-content: center;

        &:not(.selected) {
            opacity: 0;
        }
    }

    && {
        .control-dots {
            display: none;
        }
    }
`

const ImageCard = styled.div.attrs(props => ({
    style: {
        width: `${props.width}px`,
        paddingBottom: `${props.paddingBottom}%`
    }
}))`
    height: 0;
    max-width: 100%;
    padding-bottom: 56.25%;
    background: url(${props => props.background}) no-repeat center/cover;
    margin-bottom: 0;
    background-position: top;
    border: 7px solid white;
    position: relative;
    margin: 0 auto;

    @media ${devices.small} {
        border-width: 3px;
    }
`

const CarouselFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`

const PhotoInfo = styled.div`
    color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    border-bottom: 1px solid ${colors.white};
    padding-bottom: 10px;

    @media ${devices.small} {
        font-size: 10px;
        padding-bottom: 15px;
    }
`

const Caption = styled.div`
    font-weight: 600;
    max-width: 50%;
`

const Credit = styled.div`
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: pragmatica-extended, sans-serif;
    font-weight: 400;
    font-style: normal;
`
